@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #ffffff;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

:where(.css-dev-only-do-not-override-16aepep).ant-table-wrapper
	.ant-table-column-sorter-up.active,
:where(.css-dev-only-do-not-override-16aepep).ant-table-wrapper
	.ant-table-column-sorter-down.active {
	color: #00b388 !important;
}
.ant-btn-primary {
	background-color: #00b388;
}
.ant-btn-primary:not(:disabled):hover {
	background-color: #00b388;
}
.ant-btn-link {
	color: #00b388;
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: #00b388;
	border-color: #00b388;
}
:where(.css-dev-only-do-not-override-1r7tc63).ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover
	.ant-checkbox-inner,
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
	background-color: #00b388;
}
.ant-table-wrapper .ant-table-filter-trigger.active {
	color: #00b388;
}
.ant-pagination .ant-pagination-item-active a {
	color: #00b388;
}
.ant-pagination .ant-pagination-item-active {
	border-color: #00b388;
}
.ant-pagination .ant-pagination-item-active:hover {
	border-color: #00b388;
}
.ant-pagination .ant-pagination-item-active:hover a {
	color: #00b388;
}
.anticon {
	vertical-align: middle;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	border-color: transparent !important;
	border-width: 0 !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	background-color: transparent !important;
	border: none !important;
	border-radius: 0 !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
	right: 0 !important;
	margin: 0 !important;
	width: 100%;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
	border-radius: 100%;
}
.ant-select-dropdown {
	padding: 10px;
	border-radius: 10px;
	border: 2px solid #e4e4e7;
  }
.ant-select-item-option-content {
	font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

.ant-select-selection-item{
	font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

  
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
	cursor: pointer;
}

.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
	.ant-pagination-size-changer
	)
	.ant-select-selector {
	box-shadow: none;
}
